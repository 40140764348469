<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="8">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="emotionHandle"
                  :items="allEmotions.results"
                  label="Emotion"
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="name"
                  outlined
                  label="Subemotion name"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>             
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                  v-if="!allLoadingCurd"
                >
                  {{ headercontent.title }}
                </v-btn>
                <v-progress-circular
                  v-if="allLoadingCurd"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddSubemotion",
  components: { PageHeader },
  data: () => ({
    valid: true,
    name: "",
    emotionHandle: "",
    headercontent: {
      title: "Add Subemotion",
      addlink: "/dashboard/subemotion/add",
      downloadlink: true,
    },
    nameRules: [(v) => !!v || "Subemotion Name is required"],
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        const data = {
          name: this.name,
          connectedTo: this.emotionHandle,
        }
        if (this.$route.params.id == undefined) {
          this.addSubEmotion(data);
        } else {
          this.updateSubEmotion({ id: this.id, data })
        }
      }
    },
    ...mapActions([
      "fetchEmotions",
      "addSubEmotion",
      "updateSubEmotion",
      "fetchSubEmotionById",
    ]),
  },
  computed: {
    ...mapGetters(["allLoadingCurd", "allEmotions", "currentSubEmotion"]),
  },
  async created() {
    this.fetchEmotions({ sort: "name" });
    if (this.$route.params.id != undefined) {
      await this.fetchSubEmotionById(this.$route.params.id);
      this.name = this.currentSubEmotion.name;
      this.emotionHandle = this.currentSubEmotion.connectedTo;
      this.id = this.$route.params.id;
      this.headercontent.title = "Edit Subemotion";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 300px;
  min-height: 50px;
}
</style>
